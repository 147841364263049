const DOMAIN = "QuizI18n";

const PREFIX = "quiz.";

enum I18nMessages {
  NOT_FOUND_DESCRIPTION = "not_found.description",
  NOT_FOUND_BUTTON = "not_found.button",
  IMAGE_PICKER_MODAL_TITLE = "image_picker_modal.title",
  IMAGE_PICKER_MODAL_TAKE_PHOTO_BUTTON = "image_picker_modal.take_photo_button",
  IMAGE_PICKER_MODAL_CHOOSE_FROM_LIBRARY_BUTTON = "image_picker_modal.choose_from_library_button",
  UPLOAD_IMAGE_TOAST_GENERIC_ERROR = "upload_image.toast.generic_error",
  QUESTION_HEADER_TITLE = "question_header.title",
  SINGLE_QUESTION_CTA = "home.cta",
  SINGLE_QUESTION_INTRO = "home.intro",
}

export { DOMAIN, I18nMessages, PREFIX };
