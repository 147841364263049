import React, { FC } from "react";
import {
  QuestionType,
  HostDefaultQuestionItem,
  ImageQuestionItem,
  RoundedButtonQuestionItem,
  RoundedButtonWithIconOnSelectedQuestionItem,
  SquaredButtonQuestionItem,
  HostSelectQuestionItem,
  OptionQuestionItem,
  HostHeightMetricQuestionItem,
  HostWeightMetricQuestionItem,
  HostHeightImperialQuestionItem,
  HostWeightImperialQuestionItem,
  HostRankQuestionItem,
  CheckboxQuestionItem,
  QuestionDescriptions,
  QuestionDescriptionId,
  QuestionProjection,
  QuestionDescriptionProvider,
  QuestionItems as QuestionItemsType,
  Question,
  QuestionItemProvider,
  SizeGuide,
  PhotosDescription,
  SilhouetteGuide,
  StyleDescription,
  StrikeOutAvoidButtonWithIconQuestionItem,
  StrikeOutAvoidButtonQuestionItem,
  HostInputPillsQuestionItem,
  StrikeOutButtonWithColorQuestionItem,
  StrikeOutButtonWithPrintQuestionItem,
  HostDateQuestionItem,
  ButtonWithIconQuestionItem,
} from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { HostImageUploadQuestionItem } from "../../../../components/organisms/question/components/hostImageUploadQuestionItem/HostImageUploadQuestionItem";
import { HostScreenQuestionItem } from "../../../../components/organisms/question/components/hostScreenQuestionItem/HostScreenQuestionItem";
import { HostScreenWithSummaryQuestionItem } from "../../../../components/organisms/question/components/hostScreenWithSummaryQuestionItem/HostScreenWithSummaryQuestionItem";
import { DOMAIN, PREFIX } from "../../../../i18n/i18n";

const questionItems: QuestionItemsType = {
  [QuestionType.HOST_DEFAULT]: HostDefaultQuestionItem,
  [QuestionType.HOST_SCREEN]: HostScreenQuestionItem,
  [QuestionType.HOST_SCREEN_WITH_SUMMARY]: HostScreenWithSummaryQuestionItem,
  [QuestionType.IMAGE]: ImageQuestionItem,
  [QuestionType.ROUNDED_BUTTON]: RoundedButtonQuestionItem,
  [QuestionType.ROUNDED_BUTTON_WITH_ICON_ON_SELECTED]: RoundedButtonWithIconOnSelectedQuestionItem,
  [QuestionType.SQUARED_BUTTON]: SquaredButtonQuestionItem,
  [QuestionType.HOST_SELECT]: HostSelectQuestionItem,
  [QuestionType.OPTION]: OptionQuestionItem,
  [QuestionType.HOST_HEIGHT_METRIC]: HostHeightMetricQuestionItem,
  [QuestionType.HOST_WEIGHT_METRIC]: HostWeightMetricQuestionItem,
  [QuestionType.HOST_HEIGHT_IMPERIAL]: HostHeightImperialQuestionItem,
  [QuestionType.HOST_WEIGHT_IMPERIAL]: HostWeightImperialQuestionItem,
  [QuestionType.HOST_RANK]: HostRankQuestionItem,
  [QuestionType.CHECKBOX]: CheckboxQuestionItem,
  [QuestionType.HOST_DATE]: HostDateQuestionItem,
  [QuestionType.HOST_IMAGE_UPLOAD]: HostImageUploadQuestionItem,
  [QuestionType.SOCIAL_NETWORKS_INPUT]: () => null,
  [QuestionType.STRIKE_OUT_AVOID_BUTTON_WITH_ICON]: StrikeOutAvoidButtonWithIconQuestionItem,
  [QuestionType.STRIKE_OUT_AVOID_BUTTON]: StrikeOutAvoidButtonQuestionItem,
  [QuestionType.HOST_INPUT_PILLS]: HostInputPillsQuestionItem,
  [QuestionType.STRIKE_OUT_BUTTON_WITH_COLOR]: StrikeOutButtonWithColorQuestionItem,
  [QuestionType.STRIKE_OUT_BUTTON_WITH_PRINT]: StrikeOutButtonWithPrintQuestionItem,
  [QuestionType.BUTTON_WITH_ICON]: ButtonWithIconQuestionItem,
};

const questionDescriptions: Partial<QuestionDescriptions> = {
  [QuestionDescriptionId.QUIZ_SIZE_GUIDE]: SizeGuide,
  [QuestionDescriptionId.QUIZ_SILHOUETTE_GUIDE]: SilhouetteGuide,
  [QuestionDescriptionId.QUIZ_STYLE_DESCRIPTION]: StyleDescription,
  [QuestionDescriptionId.QUIZ_PHOTOS_DESCRIPTION]: PhotosDescription,
};

interface QuestionFormProps {
  readonly question: QuestionProjection;
  readonly onOpenSilhouetteGuide: () => void;
  readonly onOpenSizeGuide: () => void;
}
const QuestionForm: FC<QuestionFormProps> = ({ question, onOpenSilhouetteGuide, onOpenSizeGuide }) => (
  <QuestionItemProvider questionItems={questionItems}>
    <QuestionDescriptionProvider
      domain={DOMAIN}
      i18nPrefix={PREFIX}
      portalHostName="Quiz"
      questionDescriptions={questionDescriptions}
      onOpenSilhouetteGuide={onOpenSilhouetteGuide}
      onOpenSizeGuide={onOpenSizeGuide}
    >
      <Question key={question.id} domain={DOMAIN} question={question} questionParentId="" />
    </QuestionDescriptionProvider>
  </QuestionItemProvider>
);

export { QuestionForm };
