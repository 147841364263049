/* eslint-disable react/prop-types */
import React from "react";
import { Spinner } from "@lookiero/aurora";
import {
  HostScreenWithSummaryQuestionItem as HostScreenWithSummaryQuestionItemCommonUi,
  QuestionItem,
  QuestionType,
} from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { useStaticInfo } from "../../../../../hooks/useStaticInfo";
import { useViewQuizForSegment } from "../../../../../hooks/useViewQuizForSegment";
import { DOMAIN } from "../../../../../i18n/i18n";
import { HostScreenCta } from "../hostScreenCta/HostScreenCta";

const HostScreenWithSummaryQuestionItem: QuestionItem<QuestionType.HOST_SCREEN_WITH_SUMMARY> = ({
  question,
  level,
  children,
  questionParentId,
}) => {
  const { segment } = useStaticInfo();
  const {
    id,
    quiz: [quiz],
  } = useViewQuizForSegment({ segment });

  const dependenciesLoaded = id && quiz !== undefined;

  if (!dependenciesLoaded) {
    return <Spinner />;
  }

  return (
    <HostScreenWithSummaryQuestionItemCommonUi
      cta={<HostScreenCta question={question} quiz={quiz} quizId={id} />}
      domain={DOMAIN}
      level={level}
      question={question}
      questionParentId={questionParentId}
    >
      {children}
    </HostScreenWithSummaryQuestionItemCommonUi>
  );
};

export { HostScreenWithSummaryQuestionItem };
