import React, { FC, useMemo } from "react";
import { View, ViewStyle } from "react-native";
import { Step, StepContainer } from "@lookiero/aurora";
import { useI18n } from "@lookiero/i18n-react";
import { QuestionProjection, questionIsHostScreen, useAnswers } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { DOMAIN } from "../../../../../i18n/i18n";
import { style } from "./HostScreenStepper.style";

const groupsFromDefinition = (definition: QuestionProjection[]): string[] => [
  ...new Set(definition.filter(questionIsHostScreen).map((question) => question.metadata.groupTranslationKey)),
];

interface HostScreenStepperProps {
  readonly active: string;
  readonly style?: ViewStyle;
}
const HostScreenStepper: FC<HostScreenStepperProps> = ({ active, style: customStyle }) => {
  const { formatMessage } = useI18n({ domain: DOMAIN });
  const { questionsFilteredByAnswers } = useAnswers();
  const groups = useMemo(() => groupsFromDefinition(questionsFilteredByAnswers), [questionsFilteredByAnswers]);
  const activeIndex = groups.indexOf(active);

  return (
    <View style={[style.container, customStyle]} testID="host-screen-stepper">
      <StepContainer active={activeIndex} style={style.stepContainer}>
        {groups.map((group) => (
          // We cannot extract this to a component due to the StepContainer's implementation
          <Step key={group} testID={group} text={formatMessage({ id: group })} />
        ))}
      </StepContainer>
    </View>
  );
};

export { HostScreenStepper };
