import React, { FC, useCallback, useEffect, useMemo } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-native";
import { Button } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { CommandStatus } from "@lookiero/messaging-react";
import { useLogger } from "@lookiero/sty-psp-logging";
import {
  ScreenProjection,
  isChildQuestion,
  nextQuestionSlug,
  useAnswers,
  useAnswersForQuestion,
  useAnswersValidation,
} from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { QuizProjection } from "../../../../../../../projection/quiz/quiz";
import { useCompleteQuiz } from "../../../../../../domain/quiz/react/useCompleteQuiz";
import { useFillQuiz } from "../../../../../../domain/quiz/react/useFillQuiz";
import { useTrackFillQuiz } from "../../../../../../tracking/useTrackFillQuiz";
import { useTrackQuestionError } from "../../../../../../tracking/useTrackQuestionError";
import { useTrackStartQuiz } from "../../../../../../tracking/useTrackStartQuiz";
import { useDefinition } from "../../../../../hooks/useDefinition";
import { useStaticInfo } from "../../../../../hooks/useStaticInfo";
import { DOMAIN } from "../../../../../i18n/i18n";
import { Routes } from "../../../../../routing/routes";

interface HostScreenCtaProps {
  readonly quizId: string;
  readonly quiz: QuizProjection;
  readonly question: ScreenProjection;
}
const HostScreenCta: FC<HostScreenCtaProps> = ({ quizId, quiz, question }) => {
  const { state } = useLocation();
  const hasNavigatedWithValidationIssues = state?.validationIssues;
  const { basePath, country, segment } = useStaticInfo();
  const navigate = useNavigate();
  const logger = useLogger();

  const { experiment } = useDefinition();
  const { questionsFilteredByAnswers } = useAnswers();
  const { validateHostScreen, validation } = useAnswersValidation();

  const text = useI18nMessage({ domain: DOMAIN, id: question.metadata.ctaTranslationKey });

  const slug = useMemo(
    () => nextQuestionSlug({ question, questions: questionsFilteredByAnswers }),
    [question, questionsFilteredByAnswers],
  );

  const trackQuestionError = useTrackQuestionError({
    group: question.metadata.groupTranslationKey,
    country,
    segment,
    slug: question.metadata.slug,
    quizId,
  });

  const [fillQuiz, fillQuizStatus] = useFillQuiz({
    quizId,
    country,
    segment,
    hostId: question.id,
    experiment,
    logger,
  });
  const trackStartQuiz = useTrackStartQuiz({ country, segment });
  const trackFillQuiz = useTrackFillQuiz({
    country,
    segment,
    quizId,
    slug: question.metadata.slug,
  });
  const [completeQuiz, completeQuizStatus] = useCompleteQuiz({
    country,
    segment,
    experiment,
    logger,
  });

  const { answers: questionAnswers } = useAnswersForQuestion({ question });

  useEffect(() => {
    if (!hasNavigatedWithValidationIssues) {
      return;
    }

    validateHostScreen({ questionId: question.id });
  }, [hasNavigatedWithValidationIssues, question.id, validateHostScreen]);

  const handleOnPress = useCallback(async () => {
    validateHostScreen({ questionId: question.id });

    trackQuestionError({ validation });

    const hasValidationError = validation?.reduce(
      (acc, v) => acc || isChildQuestion({ questionId: v.questionId, question }),
      false,
    );

    if (hasValidationError) {
      return;
    }

    trackStartQuiz({ quiz });

    await fillQuiz({ answers: questionAnswers });

    trackFillQuiz({
      group: question.metadata.groupTranslationKey,
      question: question.metadata.slug,
      answers: questionAnswers,
    });

    if (slug) {
      navigate(generatePath(`${basePath}/${Routes.QUESTION}`, { slug, segment: segment.toLowerCase() }), {
        state: { ...state, validationIssues: false },
      });
    } else {
      await completeQuiz({ quizId });
    }
  }, [
    basePath,
    completeQuiz,
    fillQuiz,
    navigate,
    question,
    questionAnswers,
    quiz,
    quizId,
    segment,
    slug,
    state,
    trackFillQuiz,
    trackQuestionError,
    trackStartQuiz,
    validateHostScreen,
    validation,
  ]);

  return (
    <Button
      busy={fillQuizStatus === CommandStatus.LOADING || completeQuizStatus === CommandStatus.LOADING}
      testID="host-screen-cta"
      onPress={handleOnPress}
    >
      {text}
    </Button>
  );
};

export { HostScreenCta };
