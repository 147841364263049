import React, { FC } from "react";
import { ALIGN, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { ButtonIcon } from "@lookiero/sty-psp-ui";
import { DOMAIN, I18nMessages } from "../../../../i18n/i18n";
import { Header } from "../Header";
import { ButtonIconPlaceholder } from "../buttonIconPlaceholder/ButtonIconPlaceholder";

interface QuestionHeaderProps {
  readonly onBack?: () => void;
}
const QuestionHeader: FC<QuestionHeaderProps> = ({ onBack }) => {
  const titleText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.QUESTION_HEADER_TITLE });

  return (
    <Header testID="question-header">
      <ButtonIcon name="arrow_left" testID="back-button-icon" onPress={onBack} />
      <Text align={ALIGN.CENTER} level={3} action>
        {titleText}
      </Text>
      <ButtonIconPlaceholder />
    </Header>
  );
};

export { QuestionHeader };
